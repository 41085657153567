/**
 * 所有埋点需要使用的工具方法
 */

// 常量
const { 
  TRK_ATTR_MAP, TRK_ATTR_PREFIX,
  CACHE_KEY_DATA, CACHE_KEY_EXPIRES,
  CACHE_MAX_LENGTH, CACHE_MAX_TIME,
  VALUE_DATA_REGEX, VALUE_HOLDER_REGEX,
} = require('./_constants');
const store = localStorage;

/**
 * Lodash 工具方法
 * @todo 这里打包后多出了5kb，需要自己写，减少大小
 */
const zipObjectDeep = require('lodash.zipobjectdeep');

/**
 * 判断是否是移动端
 * refer https://stackoverflow.com/a/14301832/4376845
 */
const isMobile = () => {
  // 
  return typeof window.orientation !== 'undefined';
};

/**
 * 获取 URL 参数
 * refer https://stackoverflow.com/a/21903119/4376845
 */
const getUrlParameter = sParam => {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;
  for(i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');
    if(sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
};

/**
 * 获取节点的 index
 * refer https://stackoverflow.com/a/5913984/4376845
 * 
 * @param child 节点
 * @return 返回 index
 */
const getNodeIndex = child => {
  var i = 0;
  while((child = child.previousElementSibling) != null) 
    i++;
  return i;
}


/**
 * 动态加载JS
 * 
 * @param url JS URL
 * @param callback load Callback
 */
const loadScript = (url, callback) => {
  let script = document.createElement('script');
  script.src = url;
  callback && (script.onload = callback);
  document.body.appendChild(script);
}

/**
 * 获取当前根URL
 */
const getRootUrl = () => document.location.protocol + '//' + document.location.host;

/**
 * 获取当前页面的来源
 */
const getSource = () => {
  if(document.referrer.indexOf(getRootUrl()) === 0) {
    return '';
  } else {
    return getUrlParameter('utm_source') || 'other';
  }
};

/**
 * 获取当前页面和前一个页面的 storeKey
 * @todo 可能数据会过长，后续需要压缩存储，可以MD5方式
 */
const getStoreKey = () => location.pathname + location.search;
const getReferrerStoreKey = () => getSource() ? '' : document.referrer.replace(getRootUrl(), '');

/**
 * 获取 LS 数据
 * 
 * @param key 键值
 * @return 返回获取到的数据
 */
const getDmtStore = key => {
  if(typeof key === 'string') {
    try {
      var expires = (store.getItem(CACHE_KEY_EXPIRES) || 0) * 1;
      // 如果达到了缓存过期时间，更新数据
      if(Date.now() > expires) {
        store.setItem(CACHE_KEY_DATA, '[]');
        store.setItem(CACHE_KEY_EXPIRES, expires);
        return undefined;
      } else {
        var items = [];
        try {
          var arr = JSON.parse((store.getItem(CACHE_KEY_DATA) || '[]')) || [];
          if(Object.prototype.toString.call(items) === '[object Array]') {
            items = arr;
          }
        } catch(e) {}
        // 向前查找
        var i = items.length;
        while(i-- > 0) {
          // 如果
          if(key === items[i]['key']) {
            // 只要获取了数据，更新一下缓存过期时间
            store.setItem(CACHE_KEY_EXPIRES, Date.now() + CACHE_MAX_TIME);
            return items[i];
          }
        }
      }
    } catch(e) {
      console.warn('Get storage failed.', e);
      return false;
    }
    
  }
  return undefined;
};

/**
 * 设置 LS 数据
 * 
 * @param key 键值
 * @param data 数据
 * @return 返回是否设置成功
 */
const setDmtStore = (key, data) => {
  if(typeof key === 'string') {
    try {
      if(typeof data !== 'object') {
        return false;
      }
      data.key = key;
      var items = [];
      // 向后存储
      try {
        var arr = JSON.parse((store.getItem(CACHE_KEY_DATA) || '[]')) || [];
        if(Object.prototype.toString.call(items) === '[object Array]') {
          items = arr;
        }
      } catch(e) {}
      // 如果最后一个值的key和当前一致，就改写它；否则推入新的数据
      var latestItem = items.length ? items[items.length - 1] : {};
      if(latestItem.key === key) {
        items[items.length - 1] = data;
      } else {
        items.push(data);
      }
      // 最大限制 @todo 需要测试是否有性能瓶颈
      var remainLength = CACHE_MAX_LENGTH - items.length;
      if(remainLength < 0) {
        items.splice(0, -remainLength);
      }
      store.setItem(CACHE_KEY_DATA, JSON.stringify(items));
      store.setItem(CACHE_KEY_EXPIRES, Date.now() + CACHE_MAX_TIME);
      return true;
    } catch(e) {
      console.warn('Set storage failed.', e);
      return false;
    }
  }
  return false;
};

/**
 * 通过节点上收集过来的所有数据，获取 Log 数据
 * 
 * @param collection 从节点上收集过来的数据集合，格式为 [{name, value}, ...]
 * @return 返回埋点需要的数据格式，格式为 {log: {}, ga: {}, cache: {}}
 */
const getLogObject = (collection, extraAttributes, currentTarget) => {
  let names = [];
  let values = [];
  // 存放收集的 data 数据（data-dmt-d），用于遍历完成后，修改需要替换的数据占位符
  // @todo 还需要收集原生 data 数据 
  let dataMap = {};
  let valueHolderMap = {};
  let valueHolderIndexes = [];
  collection.forEach(({ name, value }, index) => {
    let attr = TRK_ATTR_MAP[name] || TRK_ATTR_MAP._(name);
    if(!attr) {
      return;
    }
    // 收集 data 数据
    if(VALUE_DATA_REGEX.test(name)) {
      dataMap[name.substr(2)] = value;
    }
    // 收集需要替换的数据
    let isHolderValue = VALUE_HOLDER_REGEX.test(value);
    let currentIndex = values.length;
    if(typeof attr === 'string') {
      names.push(attr);
      values.push(value);
      isHolderValue && valueHolderIndexes.push(currentIndex);
    } else if(Array.isArray(attr)) {
      let len = attr.length;
      names = names.concat(attr);
      var appendValues = new Array(len).fill(value);
      values = values.concat(appendValues);
      if(isHolderValue) {
        var appendIndexes = appendValues.map((x, i) => currentIndex + i);
        valueHolderIndexes = valueHolderIndexes.concat(appendIndexes);
      }
    }
  });
  // 开始替换数据占位
  let REPLACE_VALUE_HOLDER_REGEX = new RegExp(VALUE_HOLDER_REGEX, 'g');
  valueHolderIndexes.forEach(index => {
    let value = values[index];
    if(valueHolderMap[value] !== undefined) {
      values[index] = valueHolderMap[value];
      return;
    }
    // 如果有替换的数据则替换，否则原样返回
    valueHolderMap[value] = values[index] = value.replace(REPLACE_VALUE_HOLDER_REGEX, (s0, s1) => {
      let replaceValue = dataMap[s1];
      if (!replaceValue && replaceValue !== 0 && replaceValue !== false) {
        replaceValue = extraAttributes[s1];
        if (s1.indexOf('data-') === 0 && !replaceValue && replaceValue !== 0 && replaceValue !== false) {
          return '';
        }
      }
      if (!replaceValue && replaceValue !== 0 && replaceValue !== false && currentTarget) {
        let s = s1.lastIndexOf('.');
        if (s >= 0) {
          let childSelector = s1.substr(0, s);
          let childAttribute = s1.substr(s + 1);
          if (!childSelector) {
            childSelector = childAttribute;
            childAttribute = 'text';
          }
          let child;
          if (childSelector.indexOf('body') == 0 || childSelector.indexOf('document') == 0) {
            child = document.querySelector(childSelector);
          } else {
            child = currentTarget.querySelector(childSelector);
          }
          console.log(childSelector, childAttribute, child);
          if (child) {
            replaceValue = childAttribute === 'text' ? (child.innerText || child.textContent) : child.getAttribute(childAttribute);
          }
          if (!replaceValue && replaceValue !== 0 && replaceValue !== false) {
            return '';
          }
        }
      }
      if (!replaceValue && replaceValue !== 0 && replaceValue !== false) {
        replaceValue = s0;
      }
      return replaceValue;
    });
  });
  // 压缩为 JS 对象返回
  return zipObjectDeep(names, values);
};

/**
 * 检查是否是正则字符串（以 / 开始，并且以 /i 或 / 结尾）
 * @param str 字符串
 * @return 如果是，则返回正则表达式对象 Regex，否则返回false
 */
const getPregexFromStr = str => {
  if(typeof str === 'string') {
    let matches = str.trim().match(/^\/(.*)\/(i?)$/);
    if(matches && matches[1]) {
      return new RegExp(matches[1], matches[2]);
    }
  }
  return false;
}

/**
 * 是否匹配 match
 * @param match match 配置 {host, path, query, page}
 * @param hostname 待匹配的域名
 * @param pathname 待匹配的 URL 路径
 * @param search 待匹配的 query string
 * @param pageType 待匹配的页面的 pageType
 */
const isMatch = (match, hostname, pathname, search) => {
  let { host, path, query, excludes, pageType } = match;
  if (Array.isArray(excludes) && excludes.length > 0) {
    for (let exclude of excludes) {
      if (exclude) {
        if (typeof exclude === 'string') {
          if (exclude.endsWith('*') && pathname.indexOf(exclude.substr(0, exclude.length - 1)) == 0) {
            return false;
          } else if (exclude == pathname) {
            return false;
          }
        } else if (exclude instanceof RegExp && exclude.test(pathname)) {
          return false;
        }
      }
    }
  }
  let hostRegex  = getPregexFromStr(host);
  let pathRegex  = getPregexFromStr(path);
  let queryRegex  = getPregexFromStr(query);
  let pageTypeRegex = getPregexFromStr(pageType);
  let matched = true;
  matched = matched && (hostname === host) || (hostRegex && hostRegex.test(hostname));

  matched = matched && (
    !path || ( // 如果没有 path，则匹配
      path && ( 
        (pathname === path) || // 如果有path，path和当前pathname相等，则匹配
        (pathRegex && pathRegex.test(pathname)) // 如果有path，且满足正则匹配，则匹配
      )
    )
  );
  matched = matched && (
    !query || ( // 如果没有 query，则匹配
      search && ( 
        (search === query) || // 如果有query，query和当前search相等，则匹配
        (queryRegex && queryRegex.test(search)) // 如果有query，且满足正则匹配，则匹配
      )
    )
  );
  matched = matched && (
    !pageType || ( // 如果没有 pageType，则匹配
      pageType && ( 
        (pageType === window.pageType) || // 如果有 pageType，pageType 和当前 pageType 相等，则匹配
        (Array.isArray(pageType) && pageType.indexOf(window.pageType) >= 0) ||
        (pageTypeRegex && pageTypeRegex.test(window.pageType)) // 如果有 pageType，且满足正则匹配，则匹配)
      )
    )
  );
  return matched;
}

module.exports = {
  isMobile,
  getUrlParameter,
  getSource,
  getStoreKey,
  getNodeIndex,
  getReferrerStoreKey,
  getDmtStore,
  setDmtStore,
  zipObjectDeep,
  getLogObject,
  getPregexFromStr,
  isMatch,
  loadScript,
};
