module.exports = {
  id: 19,
  matches: [
    {
      title: '品牌首页',
      host: '/^www.([a-z0-9]+.)?(dealmoon|dazhe).[a-z.]{2,10}/i',
      path: '/^(/(cn|bg|en))?/brands$/i',
      query: '',
      screenName: ''
    }
  ],
  defines: [
    {
      selector: 'body',
      title: '',
      names: ['d-pgn', 'd-business-unit', 'd-domain-id', 'g-l', 'g-c'],
      values: ['brand', 'dm', 'brand', 'pgn:[pgn]', 'dm-brand-click']
    },
    {
      selector: 'section.top .brands>a',
      title: '',
      names: ['g-a'],
      values: ['click-dm-brand-recommend-[i]'],
      click: true
    },
    {
      selector: '.search-results li>a',
      title: '',
      names: ['g-a', 'd-value'],
      values: ['click-dm-brand-search', '[c]'],
      click: true
    },
    {
      selector: '.category-wrapper .categories>a',
      title: '',
      names: ['g-a'],
      values: ['click-dm-brand-category'],
      click: true
    },
    {
      selector: '.brand-cooperation',
      title: '',
      names: ['g-a'],
      values: ['click-dm-brand-collaboration'],
      click: true
    },
    {
      selector: '.brand-container .brand-item',
      title: '',
      names: ['g-a'],
      values: ['click-dm-brand-list'],
      click: true
    }
  ]
}
