const Dmtrk = require('./Dmtrk');
var { loadScript } = require('./_utils');

module.exports = config => {
  // 先获取一次队列
  var paramQueue = window.dmtrk ? window.dmtrk.q : [];
  // 暴露到 window
  window.dmtrk = new Dmtrk(config, paramQueue);
  // 监听 window.message 选择加载 sdk 配置
  const loadSdk = e => {
    var { type, action, data } = e.data || {};
    // 在接收到 sdk config server hello 的时候，主动加载 sdk js
    if(type === '__DMT_MESSAGE__' && action === 'hello') {
      // 需要检查是否是dealmoon域
      if(/^https?:\/\/(dmtrk|mock)\.dealmoon\.net/.test(e.origin)) {
        // 加载 sdk js，并初始化
        loadScript(`${e.origin}/sdk-${data}.js`, () => {
          dmtrkSdkInit(config.appId);
        });
      } else {
        console.warn('Invalid config server message!');
      }
      // 处理完以后移除监听
      window.removeEventListener('message', loadSdk);
    }
  }
  window.addEventListener('message', loadSdk);
}
