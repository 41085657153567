/**
 * Track 的核心方法，返回的是一个实例
 */
const { cookie } = require('dm-utils-ts');
var { TYPE_GA, TYPE_LOG, LOG_SERVER, GA_ID, TRK_ATTR_MAP } = require('./_constants');
var { isMobile, getSource, getStoreKey, getReferrerStoreKey, setDmtStore, getDmtStore } = require('./_utils');
var DomTrack = require('./DomTrack');

let win = window;

var inFrame = false;
try {
  inFrame = win.top !== win;
} catch (err) {
  inFrame = true;
}
class Dmtrk {
  /**
   * 初始化
   * 
   * @param config 预先配置的埋点数据
   * @param paramQueue 保存的埋点队列
   */
  constructor(config, paramQueue = []) {
    if (inFrame) {
      console.log('Dmtrk disabled: frame page.');
      return;
    }
    console.log('Dmtrk init');
    var _this = this;
    var udid = cookie.readCookie('udid');
    this.userId = 0;
    this.pageName = '';
    this.rip = {};
    this.evar = {};
    this.isMobile = isMobile();
    // 获取 store 键值，用于获取 rip 和 evar
    this.storeKey = getStoreKey();
    // 获取前一个页面的 storeKey
    this.referrerStoreKey = getReferrerStoreKey();
    // 获取前一个页面的 cache，包括了 rip 和 evar（转换数据）
    this.cache = getDmtStore(this.referrerStoreKey) || {};
    // @todo 以下获取方式后续需要写到配置
    this.lang = win.dmLang || 'cn';  // 默认语言 cn
    this.countryCode = win.countryCode || 'US'; // 默认北美站
    this.logServer = LOG_SERVER[this.countryCode] + (udid ? '?udid=' + udid : ''); // Add log 的接口地址
    this.gaId = GA_ID[this.countryCode]; // GA ID

    // 获取 GA instance，执行以后可以放心使用 window.ga
    this._getGaInstance(this.gaId);


    // 获取 source
    this.source = getSource();
    /**
     * 事件调用
     * 
     * @param action 埋点动作
     * @param params 其余的变量数组
     */
    const instance = (action, ...params) => {
      var handle = _this[action];
      if (/^[^_]/.test(action) && typeof handle === 'function') {
        return handle.apply(_this, params);
      } else {
        console.warn(`Dmtrk cannot find action: ${action}`);
      }
    };

    // 初始化 DomTrack
    this.domTrack = new DomTrack(config, instance);

    // 执行 actionQueue
    paramQueue.forEach(param => {
      instance.apply(this, param);
    })

    return instance;
  }

  _getGaInstance(gaId) {
    if (inFrame) return;
    (function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      if (i[r]) return;
      i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments)
      }, i[r].l = 1 * new Date();
      a = s.createElement(o);
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(win, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
    ga('create', gaId, 'auto');
  }

  /**
   * 发送埋点
   * 
   * @param data 埋点数据
   */
  track(data) {
    if (inFrame) return;
    console.log('Start tracking: ', data);
    if (!this.pageName) {
      this.pageName = data.log && data.log.data ? data.log.data.pgn : '';
    }
    if (!this.pageName) {
      this.pageName = document.body.getAttribute('data-dmt-d-pgn');
    }
    // 获取上一个页面的传递数据
    var log = this.getEnhancedLogData(data.log);
    var ga = this.getEnhancedGaData(data.ga);
    if (ga && ga.eventAction && typeof ga.eventAction === 'string') {
      // 去除多余的 dm-
      ga.eventAction = ga.eventAction.replace(/(dm\-)+/g, 'dm-', 'dm-');
    }

    // GA 发送逻辑
    log && console.log('Do Log track:', this.logServer, log);
    if (ga) {
      console.log('Do GA track:', this.gaId, ga, {
        hitType: 'event',
        ...ga,
      });
      (win.ga4 || win.ga)('send', {
        hitType: 'event',
        ...ga,
      });
    }

    // 发送完成后，设置当前页面的传递数据
    (log || ga) && data.cache && setDmtStore(this.storeKey, data.cache);
  };

  /**
   * 补全Log埋点其他需要的数据
   * 
   * @param log log统计数据
   * @param cache 
   * @return 返回完整的log统计数据，可以直接发送请求了
   */
  getEnhancedLogData(log, cache = {}) {
    // log 埋点必要条件是带有 log.name
    // getSource 
    if (log && log.name) {
      // 处理获取和设置 RIP
      var { rip = {}, evar = {} } = this.cache;
      // 设置前一个页面的转换数据
      var data = { ...evar, ...log.data };
      // 设置index
      return {
        command: 'log/addlog',
        version: '1.0',
        platform: this.isMobile ? 'PC' : 'WAP',
        userId: this.userId,
        pageName: this.pageName,
        source: this.source,
        lang: this.lang,
        ...rip,
        ...log,
        data,
      };
    }
  }
  /**
   * 补全GA埋点其他需要的数据
   * 
   * @param log log统计数据
   * @return 返回完整的log统计数据，可以直接发送请求了
   */
  getEnhancedGaData(ga) {
    if (inFrame) return;
    console.log(`getEnhancedGaData`, ga);
    // ga 埋点必要条件是带有 ga.eventAction
    if (ga && ga.eventAction) {
      let label = ga.eventLabel || '';
      // Myla 需求，额外添加的 label 信息
      // 王莹需求，最后三个顺序一定要是 yh|pf|pgn
      let labelArr = label.split('|');
      if (!/pf:/.test(label)) {
        labelArr.splice(-1, 0, 'pf:' + (this.isMobile ? 'wap' : 'pc'));
      }
      if (!/yh:/.test(label)) {
        labelArr.splice(-2, 0, 'yh:' + (this.userId || 0));
      }
      if (!/pgn:/.test(label)) {
        labelArr.push('pgn:' + (this.pageName || ''));
      }
      label = labelArr.join('|').replace(/\|{1,}/g, '|');
      // label = label.replace(/pgn\:[^|]+/g, ($0) => {
      //   return $0.replace(/[\-_\s\.]/g, '')
      // });
      // @todo 还有更多的 label 字段
      return {
        dimension22: this.lang, // 王莹需求，默认增加lang
        eventCategory: 'web',
        ...ga,
        eventLabel: label,
      }
    }
  }

  /**
   * 设置登录用户ID，用户ID将使用在DM和GA
   * 
   * @param userId 用户ID
   */
  setUserId(userId) {
    this.userId = userId;
  }
  /**
   * 清除登录用户ID
   */
  clearUserId() {
    this.userId = null;
  };

  /**
   * 设置转换变量，转换变量将存储到前端缓存，并一直在后续页面生效，直到浏览器关闭或被主动清除
   * 
   * @param data 转换变量数据
   */
  setEvar(data) {
    this.evar = { ...this.evar, ...data };
  };
  /**
   * 清除转换变量
   */
  clearEvar() {
    this.evar = {};
  };

  /**
   * 设置RIP
   * @param data rip 数据格式为  { name: '', value: '', position: '' } 
   */
  setRip(data) {
    this.rip = { ...this.rip, ...data };
  };
  /**
   * 清除RIP
   */
  clearRip() {
    this.rip = {};
  };
}

module.exports = Dmtrk;
