module.exports = {
  id: 31,
  matches: [
    {
      title: '排行榜首页',
      host: '/^(www|m).([a-z0-9]+.)?(dealmoon|dazhe).[a-z.]{2,10}/i',
      path: '/^(\\/(cn|bg|en))?\\/popular\\-deals$/i',
      query: '',
      screenName: ''
    }
  ],
  defines: [
    {
      selector: 'body',
      title: '',
      names: ['d-pgn', 'd-business-unit', 'd-domain-id', 'g-l', 'g-c'],
      values: ['dealchart', 'dm', 'chart', 'pgn:[pgn]', 'dm_dealchart_sp']
    },
    {
      selector: '.Tp_sp_all .Topclick_list li[data-type="sp"]',
      title: '',
      names: ['g-a'],
      values: ['dm-dealchart-sp-click-[i]'],
      click: true
    },
    {
      selector: '.Topclick_cont .more',
      title: '',
      names: ['g-a'],
      values: ['dm-dealchart-sp-click-more'],
      click: true
    },
  ]
}
