/*
 * @Author: Gang Jiang
 * @Date: 2020-02-06 09:49:16
 * @LastEditors  : Gang Jiang
 * @LastEditTime : 2020-02-06 12:36:58
 * @Description: 
 */
module.exports = {
  id: 22,
  matches: [
    {
      title: '攻略首页',
      host: '/^www.([a-z0-9]+.)?(dealmoon|dazhe).(com|net|de)$/i',
      path: '/^\\/guide$/i',
      query: '',
      screenName: ''
    }
  ],
  defines: [
    {
      selector: 'body',
      title: '',
      names: ['d-pgn', 'd-business-unit', 'd-domain-id', 'g-l', 'g-c'],
      values: ['guidehome', 'dm', 'guide', 'pgn:[pgn]', 'dm-guide-click'],
    },
    {
      selector: '.img-categories .category-link',
      title: '',
      names: ['g-a', 'd-value'],
      values: ['click-dm-guide-home-category', '[c]'],
      click: true
    },
  ],
}