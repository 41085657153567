/**
 * 通过DOM节点上配置的埋点属性，进行对应的LOG或GA埋点
 */
const { 
  TYPE_GA, TYPE_LOG, EVENT_CLICK,
  TRK_ATTR_PREFIX, TRK_ATTR_PREFIX_SUBSTR_LENGTH,
  TRK_ATTR_ACTION,
  VALUE_INDEX_REGEX, VALUE_HOLDER_REGEX,
  VALUE_CONTENT_REGEX,
} = require('./_constants');
const { getLogObject, getNodeIndex, getPregexFromStr, isMatch } = require('./_utils');

const ROOT_NODE = document;
let dmtrk = null;

class DomTrack {
  /**
   * 初始化
   */
  constructor(config, dmtrkInstance) {
    if(window.__dmtrk_sdk_env__) {
      return console.warn('window.__dmtrk_sdk_env__ is TRUE, DOM tracking is inactive.');
    }
    dmtrk = dmtrkInstance;
    this.config = config;
    console.log('Dom Track Init');
    // 根节点默认为document
    this.rootNode = ROOT_NODE;
    this.getMatchedDefines();
    // 从根节点开始写入配置
    this.defineToDom(this.rootNode);
    // 从根节点开始绑定
    this.bindEvent(this.rootNode);
    // 从根节点开始监听变化
    this.observeMutation(this.rootNode);
    return this;
  }

  /**
   * 获取匹配的配置
   */
  getMatchedDefines() {
    const { prefix, pages } = this.config;
    // 当前页面的信息
    const { hostname, pathname, search } = document.location;
    let i = 0, page, isPageMatch = false;;
    // 查找页面定义
    while(page = pages[i++]) {
      const { matches = [], defines = [] } = page;
      // 检查当前网页是否匹配规则
      let j = 0, match;
      while(match = matches[j++]) {
        if(isMatch(match, hostname, pathname, search)) {
          isPageMatch = true;
          break;
        }
      }
      if(isPageMatch) {
        this.matchedDefines = defines;
        break;
      }
    }
  }

  /**
   * 将配置文件的数据，写入到Dom的属性中
   * 
   * @param root 写入属性的根节点，可以是 document 或 Element
   */
  defineToDom(root) {
    if(!this.matchedDefines) {
      return;
    }
    const { prefix, pages } = this.config;
    this.matchedDefines.forEach(({ names = [], values = [], selector = 'a', click = false }) => {
      try {
        if(names.length !== values.length) {
          throw new Error('Defined names and values is not valid.');
          return;
        }
        let matchedEles = [];
        // 有些选择器可能就是自身，所以在querySelectorAll之前需要matches检查一次
        // Refer: https://dev.w3.org/2006/webapi/selectors-api2/#matches
        if(root.matches && root.matches(selector)) {
          matchedEles = [root];
        } else {
          matchedEles = root.querySelectorAll(selector);
        }
        // console.log({
        //   matchedEles,
        //   names,
        //   values,
        //   selector,
        //   click
        // });
        matchedEles.forEach(ele => {
          if(ele.setAttribute) {
            click && ele.setAttribute(prefix, '');
            names.forEach((name, index) => {
              ele.setAttribute(prefix + '-' + name, values[index]);
            });
          }
        });
      } catch(e) { 
        console.warn('Defined dom failed.', e);
      }
    });
  }

  /**
   * 绑定事件统一埋点的事件
   * 
   * @param root 绑定事件的节点，可以是 document 或 Element
   */
  bindEvent(root) {
    // 获取所有需要绑定事件的元素，如果root自身就有埋点属性，那就取自身
    let eles = root.hasAttribute && root.hasAttribute(TRK_ATTR_ACTION) ? 
      [root] : root.querySelectorAll(`[${TRK_ATTR_ACTION}]`);
    // 避免阻塞主线程
    setTimeout(() => {
      eles.forEach(ele => {
        // 默认先删除一下事件，防止重复绑定
        ele.removeEventListener(EVENT_CLICK, this.getTrackData);
        // 仅在捕获阶段获取，这样能获取到最外层
        ele.addEventListener(EVENT_CLICK, this.getTrackData, true);
      });
    });
  }

  /**
   * 自动监听Dom变化，并绑定事件
   * 
   * @param root 根节点
   */
  observeMutation(root) {
    // 监听 DOM 元素变化，在有增加节点时处理
    // refer: https://developer.mozilla.org/zh-CN/docs/Web/API/MutationObserver
    (new MutationObserver(mutationsList => {
      mutationsList.forEach(mutation => {
        if(mutation.type == 'childList' && mutation.addedNodes.length) {
          mutation.addedNodes.forEach(node => {
            // 判断Node类型
            if(node.nodeType === Node.ELEMENT_NODE || node.nodeType === Node.DOCUMENT_NODE) {
              this.defineToDom(node);
              this.bindEvent(node);
            }
          });
        }
      });
    })).observe(root, {
      childList: true,
      subtree: true
    });
  }

  /**
   * 获取点击事件触发的元素的所有 track 值
   * 
   * @param e 点击事件
   */
  getTrackData(e) {
    let currentTarget = e.currentTarget || e.target;
    let ele = currentTarget;
    // 已收集过的节点，用于判重，这样最深层的节点优先级最高
    let _attrMap = {};
    let collection = [];
    // 计算节点Index
    let index = getNodeIndex(ele) + 1;

    // content最多保留100个长度，防止接口数据过多
    let content = (ele.innerText || '').substr(0, 100).replace(/[\n\r]/g, ' ');
    let extraAttributes = {};
    // 向上取节点，直到取到根节点
    while(ele && ele !== ROOT_NODE) {
      // 遍历所有属性取出符合要求的属性
      let attributes = ele.attributes || [];
      let i = 0, attr;
      while(attr = attributes[i++]) {
        let { name, value } = attr;
        if(name.indexOf(TRK_ATTR_PREFIX) !== -1) {
          name = name.substr(TRK_ATTR_PREFIX_SUBSTR_LENGTH);
          if(name && _attrMap[name] === undefined) {
            _attrMap[name] = 1;
            // 如果属性是[i]，并且是占位符，计算一下index，从1开始
            if(VALUE_INDEX_REGEX.test(value)) {
              value = value.replace(new RegExp(VALUE_INDEX_REGEX, 'g'), index);
            }
            // 如果属性是[c]，并且是
            if(VALUE_CONTENT_REGEX.test(value)) {
              value = value.replace(new RegExp(VALUE_CONTENT_REGEX, 'g'), content);
            }
            collection.push({ name, value });
          }
        } else {
          if (typeof extraAttributes[name] === 'undefined') {
            extraAttributes[name] = value;
          }
        }
      }
      ele = ele.parentNode;
    }
    // 收集完数据以后，需要调用 dmtrk 发送埋点
    dmtrk('track', getLogObject(collection, extraAttributes, currentTarget));
  }
}

module.exports = DomTrack;
