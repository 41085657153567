module.exports = {
  "appId": "20181224-pc",
  "prefix": "data-dmt",
  "pages": [
    {
      "id": 1,
      "matches": [
        {
          "title": "网站首页",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^\\/?(cn|bg|en)?$/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-pgn",
            "d-business-unit",
            "d-domain-id",
            "g-l"
          ],
          "values": [
            "home",
            "dm",
            "deal",
            "pgn:[pgn]"
          ]
        },
        {
          "selector": "header .logo",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-logo-click",
            "click-dm-[pgn]-header-logo",
          ],
          "click": true
        },
        {
          "selector": "header .search_begin_btn",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-search-click",
            "click-dm-[pgn]-header-searchbutton",
            "search"
          ],
          "click": true
        },
        {
          "selector": "header .recommend_word a, header .sf_word .topa",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-value",
            "d-domain-id"
          ],
          "values": [
            "dm-search-click",
            "click-dm-[pgn]-header-searchkeyword[i]",
            "[c]",
            "search"
          ],
          "click": true
        },
        {
          "selector": "header .store",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-search-click",
            "click-dm-[pgn]-header-store"
          ],
          "click": true
        },
        {
          "selector": "header .login_new",
          "title": "",
          "names": [
            "g-c"
          ],
          "values": [
            "dm-user-click"
          ],
          "click": true
        },
        {
          "selector": "header .login_new .login_after",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-header-user"
          ],
          "click": true
        },
        {
          "selector": "header .banner .change-language-cn",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-cn-click",
            "click-dm-[pgn]-header-chinese"
          ],
          "click": true
        },
        {
          "selector": "header .banner .change-language-en",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-en-click",
            "click-dm-[pgn]-header-english"
          ],
          "click": true
        },
        {
          "selector": "header .banner .app_btn",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-app-click",
            "click-dm-[pgn]-header-app"
          ],
          "click": true
        },
        {
          "selector": "header .banner .contact_top_btn",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-contactus-click",
            "click-dm-[pgn]-header-contactus"
          ],
          "click": true
        },
        {
          "selector": "header .banner .about-dealmoon-btn",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-aboutus-click",
            "click-dm-[pgn]-header-aboutus"
          ],
          "click": true
        },
        {
          "selector": "#home-page-banners-en > a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-banner-click",
            "click-dm-[pgn]-banner-[i]"
          ],
          "click": true
        },
        {
          "selector": "header .nav_bot",
          "title": "",
          "names": [
            "g-c"
          ],
          "values": [
            "dm-[area]-click"
          ]
        },
        // 仅绑定链接，避免多次记录
        {
          "selector": "header .nav_bot .nav-cate-1 > a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-[fullarea]-[cate1]"
          ],
          "click": true
        },

        {
          "selector": "header .nav_bot .nav-cate-2",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-[fullarea]-[cate1]-[cate2]"
          ],
          "click": true
        },
        {
          "selector": "header .nav_bot .change-language-bg",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-topbar-category-bg"
          ],
          "click": true
        },
        {
          "selector": "header .nav_bot #toExpiring",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-topbar-category-expire"
          ],
          "click": true
        },
        {
          "selector": "header .nav_bot .change-language-en",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-topbar-english"
          ],
          "click": true
        },
        {
          "selector": "header .nav_bot .change-language-cn",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-topbar-chinese"
          ],
          "click": true
        },
        {
          "selector": "header .nav_bot .search_begin_btn",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-topbar-searchbutton"
          ],
          "click": true
        },
        {
          "selector": "header .nav_bot .first_home",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-topbar-home"
          ],
          "click": true
        },
        {
          "selector": "header .nav_bot .tab-hot",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-topbar-hot"
          ],
          "click": true
        },

        {
          "selector": ".box_new",
          "title": "",
          "names": [
            "g-c"
          ],
          "values": [
            "dm-hotpick-click"
          ]
        },
        {
          "selector": ".box_new .icon-scr-left",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-hotpick-left"
          ],
          "click": true
        },
        {
          "selector": ".box_new .icon-scr-right",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-hotpick-right"
          ],
          "click": true
        },
        {
          "selector": ".box_new .scroll_num span",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-hotpick-[i]"
          ],
          "click": true
        },
        {
          "selector": ".box_new .total",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-hotpick-all"
          ],
          "click": true
        },
        {
          "selector": ".box_new .box_item",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-hotpick-deal[i]"
          ],
          "click": true
        },

        {
          "selector": "#dealsList .mlist_new_tips",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-click",
            "click-dm-[pgn]-feed-refresh"
          ],
          "click": true
        },

        {
          "selector": ".area_right",
          "title": "",
          "names": [
            "g-c"
          ],
          "values": [
            "dm-right-click"
          ]
        },

        // {
        //   "selector": "#rankList",
        //   "title": "",
        //   "names": [
        //     "g-c"
        //   ],
        //   "values": [
        //     "dm-dealchart-click"
        //   ]
        // },
        // {
        //   "selector": "#rankList .title a",
        //   "title": "",
        //   "names": [
        //     "g-a"
        //   ],
        //   "values": [
        //     "click-dm-[pgn]-right-dealchart-all"
        //   ],
        //   "click": true
        // },
        // {
        //   "selector": "#rankList .content .b_list",
        //   "title": "",
        //   "names": [
        //     "g-a"
        //   ],
        //   "values": [
        //     "click-dm-[pgn]-right-dealchart-[i]"
        //   ],
        //   "click": true
        // },
        // {
        //   "selector": ".sp-hot",
        //   "title": "",
        //   "names": [
        //     "g-c"
        //   ],
        //   "values": [
        //     "dm-sphot-click"
        //   ]
        // },
        // {
        //   "selector": ".sp-hot .sp-hot-title a",
        //   "title": "",
        //   "names": [
        //     "g-a"
        //   ],
        //   "values": [
        //     "click-dm-[pgn]-right-sphot-all"
        //   ],
        //   "click": true
        // },
        // {
        //   "selector": ".sp-hot .sp-hot-content li",
        //   "title": "",
        //   "names": [
        //     "g-a"
        //   ],
        //   "values": [
        //     "click-dm-[pgn]-right-sphot-[i]"
        //   ],
        //   "click": true
        // },

        {
          "selector": "#creditBox .ad_credit_title",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-creditcard-all"
          ],
          "click": true
        },
        {
          "selector": "#gonglueBox .gonglue_more",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-guide-more"
          ],
          "click": true
        },
        {
          "selector": "#gonglueBox .gonglue_cont",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-guide"
          ],
          "click": true
        },
        {
          "selector": "#gonglueBox .post-button-next",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-guide-right"
          ],
          "click": true
        },
        {
          "selector": "#gonglueBox .post-button-prev",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-guide-left"
          ],
          "click": true
        },
        {
          "selector": "#showPost .showBox",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-ugc"
          ],
          "click": true
        },
        {
          "selector": "#showPost .post-button-next",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-ugc-right"
          ],
          "click": true
        },
        {
          "selector": "#showPost .post-button-prev",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-ugc-left"
          ],
          "click": true
        },
        {
          "selector": "#right-store .title a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-hotstore-all"
          ],
          "click": true
        },
        {
          "selector": "#right-store .store_list li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-hotstore-[i]"
          ],
          "click": true
        },

        {
          "selector": "#todayHot",
          "title": "",
          "names": [
            "g-c"
          ],
          "values": [
            "dm-floatingbar-click"
          ]
        },
        {
          "selector": "#todayHot .wechat-box",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-wechatqrcode"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .wechat-box",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-wechatqrcode"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .wechat-box",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-wechatqrcode"
          ],
          "click": true
        },

        {
          "selector": "#todayHot .wechat-box",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-wechatqrcode"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .th_00",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-contactus"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .th_01",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-hotdeal"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .th_02",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-beauty"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .th_04",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-electronicscomputers"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .th_05",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-creditcard"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .th_07",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-app"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .change-language-en",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-english"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .change-language-cn",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-chinese"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .change-language-cn",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-chinese"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .toplink",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-top"
          ],
          "click": true
        },
        {
          "selector": "#todayHot .toplink",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-right-floatingbar-top"
          ],
          "click": true
        },
        {
          "selector": ".j-deal-list-pager a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-bottom-click",
            "click-dm-[pgn]-bottom-page[c]"
          ],
          "click": true
        },
        {
          "selector": ".j-deal-list-pager .pre_link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-bottom-click",
            "click-dm-[pgn]-bottom-pageprev"
          ],
          "click": true
        },
        {
          "selector": ".j-deal-list-pager .next_link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-bottom-click",
            "click-dm-[pgn]-bottom-pagenext"
          ],
          "click": true
        },
        // 广告类型
        {
          "selector": "#dealsList .mlist[data-ad-type=post] a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-ugc-promo-click",
            "click-dm-[pgn]-promo-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=bask_strategy] .tags a",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-value",
          ],
          "values": [
            "dm-ugc-promo-click",
            "click-dm-[pgn]-promo-[adno]",
            "[c]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=guide] a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-guide-promo-click",
            "click-dm-[pgn]-promo-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=promition] a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-adbanner-promo-click",
            "click-dm-[pgn]-promo-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=local] a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-local-promo-click",
            "click-dm-[pgn]-promo-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=sp_subject] a, #dealsList .mlist[data-ad-type=carefully_chosen_sp] a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-promo-click",
            "click-dm-[pgn]-promo-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=disclosure_group] a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-baoliao-promo-click",
            "click-dm-[pgn]-promo-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal]",
          "title": "",
          "names": [
            "g-c"
          ],
          "values": [
            "dm-deal-promo-click"
          ]
        },
        {
          "selector": "#dealsList .mlist",
          "title": "",
          "names": [
            "g-c"
          ],
          "values": [
            "dm-deal-click"
          ]
        },
        {
          "selector": "#dealsList .mlist .btn-buy",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-[pgn]-feed-buybutton"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .snapshot, #dealsList .mlist .img_wrap",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-feed-cover-p[page]no[dealno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .zoom-title, #dealsList .mlist .indextitle",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-feed-title-p[page]no[dealno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .zoom-brief",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-feed-expand-p[page]no[dealno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .zoom-collapse",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-feed-pullback-p[page]no[dealno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .dpc a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-[pgn]-feed-sp"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .stat-count-comment",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-feed-comment-p[page]no[dealno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .submit_btn",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-feed-comment-enter-p[page]no[dealno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .mc_add_pic",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-feed-comment-pic-p[page]no[dealno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .j-stat-count-fav",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-feed-favorite-p[page]no[dealno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .ib-store, #dealsList .mlist .from-web",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-feed-store-p[page]no[dealno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .ib-category, #dealsList .mlist .minfo_cart",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-feed-category-p[page]no[dealno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .mbody ul a, #dealsList .mlist .mbody p a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-[pgn]-feed-content"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .mbody .sp-in-deal a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-[pgn]-feed-post"
          ],
          "click": true
        },

        // deal promo
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .btn-buy",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-promo-buy",
            "buy-dm-[pgn]-promo-buybutton-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .snapshot, #dealsList .mlist[data-ad-type=deal] .img_wrap",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-promo-cover-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .zoom-title, #dealsList .mlist[data-ad-type=deal] .indextitle",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-promo-title-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .zoom-brief",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-promo-expand-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .zoom-collapse",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-promo-pullback-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .dpc a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-promo-buy",
            "buy-dm-[pgn]-promo-sp-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .stat-count-comment",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-promo-comment-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .submit_btn",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-promo-comment-enter-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .mc_add_pic",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-promo-comment-pic-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .j-stat-count-fav",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-promo-favorite-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .ib-store, #dealsList .mlist[data-ad-type=deal] .from-web",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-promo-store-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .ib-category, #dealsList .mlist[data-ad-type=deal] .minfo_cart",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-promo-category-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .mbody ul a, #dealsList .mlist[data-ad-type=deal] .mbody p a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-promo-buy",
            "buy-dm-[pgn]-promo-content-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .mbody .view_detail",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-promo-detail-[adno]"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=deal] .mbody .sp-in-deal a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-promo-buy",
            "buy-dm-[pgn]-promo-post-[adno]"
          ],
          "click": true
        },

        // 底部
        {
          "selector": ".footer_wrap a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-bottom-click",
            "click-dm-[pgn]-bottom-[link]"
          ],
          "click": true
        }
      ]
    },
    {
      "id": 2,
      "matches": [
        {
          "title": "折扣详情页",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/([^\\/]+\\/)?\\d{5,10}\\.html/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-business-unit"
          ],
          "values": [
            "dm"
          ]
        },
        {
          "selector": "a.btn-buy",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "buy-dm-[dt]detail-buybutton"
          ],
          "click": true
        },
        {
          "selector": "img.snapshot",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "buy-dm-[dt]detail-cover"
          ],
          "click": true
        },
        {
          "selector": "a.zoom-title",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "buy-dm-[dt]detail-title"
          ],
          "click": true
        },
        {
          "selector": ".cz_date a[title]",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "buy-dm-[dt]detail-store"
          ],
          "click": true
        },
        {
          "selector": ".article_mlist .edit-content a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "buy-dm-[dt]detail-content"
          ],
          "click": true
        },
        {
          "selector": "#spBox .tw a, .fans-recommend-item-sp a, .dm-cmt-sp a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "buy-dm-[dt]detail-sp"
          ],
          "click": true
        },
        {
          "selector": ".article_mlist .edit-content a:not(.conteditable)",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "buy-dm-[dt]detail-content"
          ],
          "click": true
        },
        {
          "selector": ".article_mlist .sp-in-deal a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "buy-dm-[dt]detail-post"
          ],
          "click": true
        },
        {
          "selector": ".article_mlist .edit-content ul a, .article_mlist .edit-content ul p a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "buy-dm-[dt]detail-content"
          ],
          "click": true
        },
        {
          "selector": ".link_icon_buy",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "buy-dm-[dt]detail-floatingbuybutton"
          ],
          "click": true
        },
        {
          "selector": "a.btn-buy.haitao",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "buy-dm-[dt]detail-haitaobuy"
          ],
          "click": true
        },
        {
          "selector": ".area_left:first-child",
          "title": "",
          "names": [
            "g-c",
            "g-l",
            "d-domain-id"
          ],
          "values": [
            "dm-[dt]-buy",
            "pgn:[dt]detail",
            "[dt]"
          ]
        },
        {
          "selector": ".recommend-sps-box .recommend-sp-img",
          "title": "推荐好货",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-dealdetail-sprecommended"
          ],
          "click": true,
        },
        {
          "selector": ".sp-scroll .dm-sp-card-left",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-[dt]detail-recommend-popup",
            "sp"
          ],
          "click": true
        },
        {
          "selector": "a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-[dt]detail-recommend-spdetail",
            "sp"
          ],
          "click": true
        },
        {
          "selector": ".dm-sp-card-buy, .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-[dt]detail-recommend-directbuybutton",
            "sp"
          ],
          "click": true
        },
        {
          "selector": ".award-vote .award-vote-group",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-votedealdetail-votegroup",
          ],
          "click": true
        },
        {
          "selector": ".award-form-sp-img img",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-votedetail-spselected",
          ],
          "click": true
        },
        {
          "selector": ".award-form-wrapper .award-form-sp > a",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-votedetail-title",
          ],
          "click": true
        },
        {
          "selector": ".award-form-wrapper .award-form-close",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-votedetail-cancel",
          ],
          "click": true
        },
        {
          "selector": ".award-form-wrapper .award-form-control-submit",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-votedetail-votebutton",
          ],
          "click": true
        },
        {
          "selector": ".award-form-wrapper .award-form-control-prev",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-votedetail-leftbutton",
          ],
          "click": true
        },
        {
          "selector": ".award-form-wrapper .award-form-control-next",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-votedetail-rightbutton",
          ],
          "click": true
        },
        // 合集内容埋点
        {
          "selector": ".collection-promote",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "g-l",
            "d-value",
            "d-domain-id",
          ],
          "values": [
            "dm-deal-click",
            "click-dm-heji-top-[i]",
            "pgn:dealheji",
            "[c]",
            "deal",
          ],
          "click": true
        },
        {
          "selector": ".collection-item-deal-wrapper .collection-item-deal",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "g-l",
          ],
          "values": [
            "dm-deal-click",
            "click-dm-heji-deal-[i]",
            "pgn:dealheji",
          ],
          "click": true
        },
        {
          "selector": ".collection-item-guide-wrapper .collection-item-guide",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "g-l",
          ],
          "values": [
            "dm-deal-click",
            "click-dm-heji-guide-[i]",
            "pgn:dealheji",
          ],
          "click": true
        },
        {
          "selector": ".collection-item-post-wrapper .collection-item-post",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "g-l",
          ],
          "values": [
            "dm-deal-click",
            "click-dm-heji-post-[i]",
            "pgn:dealheji",
          ],
          "click": true
        },
        {
          "selector": ".collection-subnav-content span",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "g-l",
            "d-domain-id",
            "d-value"
          ],
          "values": [
            "dm-topbar-click",
            "click-dm-heji-topbar-[i]",
            "pgn:dealheji",
            "deal",
            "[body .collection-header-title h1.text]",
          ],
          "click": true
        },
        {
          "selector": ".award-vote-group-item",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-awarddealdetail-awardgroup-spdetail",
          ],
          "click": true
        },
        {
          "selector": ".award-vote-group-item-buy",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-awarddealdetail-buybutton",
          ],
          "click": true
        },
      ]
    },
    {
      "id": 3,
      "matches": [
        {
          "title": "笔记详情",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/post(\\/\\w+|$)/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-business-unit",
            "d-domain-id",
          ],
          "values": [
            "dm",
            "ugc",
          ]
        },
        {
          "selector": ".dm-post-related-deals .post-related-deal > a",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-ugcpic-click",
            "click-dm-ugcpicdetail-deal"
          ],
          "click": true
        },
        {
          "selector": ".edit-post-content .dm_link.dm_tracking, .edit-post-content .dm_insert_block.dm_single_product, .edit-post-content .dm_insert_block.dm_sp, .edit-post-content a[rel*='nofollow']",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-ugcpic-buy",
            "buy-dm-ugcpicdetail-content"
          ],
          "click": true
        },
        {
          "selector": ".product-item-sp[rel*='nofollow']",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-ugcpic-buy",
            "buy-dm-ugcpicdetail-relateproduct",
          ],
          "click": true
        },
        // {
        //   "selector": ".product-item-sp:not([rel*='nofollow'])",
        //   "title": "",
        //   "names": [
        //     "g-c",
        //     "g-a"
        //   ],
        //   "values": [
        //     "dm-ugcpic-click",
        //     "buy-dm-ugcpicdetail-sp"
        //   ],
        //   "click": true
        // },
        {
          "selector": ".product-item-deal",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-ugcpic-click",
            "buy-dm-ugcpicdetail-deal"
          ],
          "click": true
        },
        {
          "selector": ".dm-post-detail-likes-content a[data-dmt-d-post-id], .dm-post-detail-likes-content a[data-dmt-d-guide-id]",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-post-click",
            "click-dm-ugcdetail-recommended"
          ],
          "click": true
        },
        {
          "selector": ".dm-post-modal-detail",
          "title": "",
          "names": [
            "d-domain-id",
            "g-l"
          ],
          "values": [
            "ugc",
            "pgn:ugcpicdetail"
          ]
        }
      ]
    },
    {
      "id": 4,
      "matches": [
        {
          "title": "长文章详情",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/guide\\/\\w+/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-business-unit",
            "g-l"
          ],
          "values": [
            "dm",
            "pgn:[pgn]"
          ]
        },
        {
          "selector": ".dm-sg-goods--sp a",
          "title": "",
          "names": [
          ],
          "values": [
          ],
        },
        {
          "selector": ".edit-content a[rel*='nofollow'], .edit-content a[rel*='noopener']",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-dp-id",
          ],
          "values": [
            "dm-[pgn]guide-buy",
            "buy-dm-[pgn]guidedetail-content",
            "[data-sp-id]-[.content.title]"
          ],
          "click": true
        },
      ]
    },
    {
      "id": 5,
      "matches": [
        {
          "title": "单品详情",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/product\\/([^\\/]+\\/)?\\d{1,}/i",
          "query": "",
          "pageType": "product_detail",
          "screennames": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "g-l",
            "g-c",
            "d-domain-id",
            "d-business-unit"
          ],
          "values": [
            "pgn:spdetail",
            "dm-sp-click",
            "sp",
            "dm"
          ]
        },
        {
          "selector": "#sp_keyword",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-search"
          ],
          "click": true
        },
        {
          "selector": ".cn_history_data_list li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-searchrecommend-searchhistory"
          ],
          "click": true
        },
        {
          "selector": ".history_data_list li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-searchrecommend-inputmatch"
          ],
          "click": true
        },
        {
          "selector": ".product-img-main",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-spdetail-pic"
          ],
          "click": true
        },
        {
          "selector": ".product-main-box .product-buy",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-spdetail-instantbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".product-main-box .product-info-content > a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-spdetail-title"
          ],
          "click": true
        },
        {
          "selector": ".product-main-box .product-img-main",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-spdetail-pic"
          ],
          "click": true
        },
        {
          "selector": ".product-similar .dm-sp-card-buy, .product-similar .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-spdetail-similarsp-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".product-similar .dm-sp-card-detail-button, .product-similar .dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-spdetail-similarsp-viewdetailbutton"
          ],
          "click": true
        },
        {
          "selector": ".guess-like-group .dm-sp-card-buy, .guess-like-group .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-spdetail-guesslikesp-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".guess-like-group .dm-sp-card-detail-button, .guess-like-group .dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "buy-dm-spdetail-guesslikesp-viewdetailbutton"
          ],
          "click": true
        },
        {
          "selector": ".product-main-box .discount ul a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-spdetail-link"
          ],
          "click": true
        },
        {
          "selector": ".product-main-box .discount ul a, .product-main-box .recommend a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-spdetail-link"
          ],
          "click": true
        },
        {
          "selector": ".product-discount h3 a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-dealdetail"
          ],
          "click": true
        },
        {
          "selector": ".product-info-tag > a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-hashtag"
          ],
          "click": true
        },
        {
          "selector": ".dm-post-user-info a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click-ugc",
            "click-dm-spdetail-ugcpic"
          ],
          "click": true
        },
        {
          "selector": ".dm-guide-item",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click-guide",
            "click-dm-spdetail-ugcguide"
          ],
          "click": true
        },
        {
          "selector": ".j-stat-count-share a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-share",
          ],
          "click": true
        },
        {
          "selector": ".j-stat-count-fav",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-bookmark",
          ],
          "click": true
        },
        {
          "selector": ".j-stat-count-comment",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-comment",
          ],
          "click": true
        },
        {
          "selector": ".product-similar .dm-sp-card-left",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-similarsp-popup",
          ],
          "click": true
        },
        {
          "selector": ".product-similar a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-similarsp-viewdetailbutton",
          ],
          "click": true
        },
        {
          "selector": ".product-related-info .more",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-hot-all",
          ],
          "click": true
        },
        {
          "selector": ".product-related-info .tab-hot a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-hot-spdetail",
          ],
          "click": true
        },
        {
          "selector": ".product-related-info .tab-new a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-new-spdetail",
          ],
          "click": true
        },
        {
          "selector": ".product-viewed .dm-sp-card-left",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-viewed-popup",
          ],
          "click": true
        },
        {
          "selector": ".product-viewed .dm-sp-card-left",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-viewed-spdetail",
          ],
          "click": true
        },
        {
          "selector": ".product-recommend-event .more",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-topic-all",
          ],
          "click": true
        },
        // {
        //   "selector": ".product-recommend-event-item",
        //   "title": "",
        //   "names": [
        //     "g-a"
        //   ],
        //   "values": [
        //     "click-dm-spdetail-topic-topicdetail",
        //   ],
        //   "click": true
        // },
        {
          "selector": ".guess-like-group .dm-sp-card-left",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-guesslikesp-popup",
          ],
          "click": true
        },
        {
          "selector": ".guess-like-group a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-guesslikesp-spdetail",
          ],
          "click": true
        },
        {
          "selector": ".product-other-deals a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-otherdealdetail",
          ],
          "click": true
        },
        {
          "selector": "#otherDealShow",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-spdetail-otherdealdetail-more",
          ],
          "click": true
        }
      ]
    },
    {
      "id": 6,
      "matches": [
        {
          "title": "单品专题详情",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/product\\/event\\/\\w+/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "g-c",
            "g-l",
            "d-domain-id",
            "d-business-unit"
          ],
          "values": [
            "dm-sp-click",
            "pgn:sp[pgn]",
            "sp",
            "dm"
          ]
        },
        {
          "selector": "#sp_keyword",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-search"
          ],
          "click": true
        },
        {
          "selector": ".cn_history_data_list li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-searchrecommend-searchhistory"
          ],
          "click": true
        },
        {
          "selector": ".history_data_list li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-searchrecommend-inputmatch"
          ],
          "click": true
        },
        {
          "selector": ".dm-sp-card-buy, .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-[pgn]-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".dm-sp-card-left",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-popup",
          ],
          "click": true
        },
        {
          "selector": "a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-spdetail",
          ],
          "click": true
        },
        {
          "selector": ".product-event-group-sort > span",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-sort",
          ],
          "click": true
        },
        {
          "selector": ".sp-fix-name li, .topic-subnav-content-width span",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-floatbar",
          ],
          "click": true
        },
        {
          "selector": ".product-recent-event-item a, .recent-event-item a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-newtopic-topicdetail",
          ],
          "click": true
        },
        {
          "selector": ".product-go-home, .recent-event-more",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-newtopic-all",
          ],
          "click": true
        },
        {
          "selector": ".product-event-tags a, .topic-tags a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-hashtag",
          ],
          "click": true
        },
        {
          "selector": "#topicBanner a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-banner",
          ],
          "click": true
        },
        {
          "selector": "#topicBanner a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-banner",
          ],
          "click": true
        },
        {
          "selector": "#topicAdvert a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-smallbanner",
          ],
          "click": true
        },
        {
          "selector": ".post-hover > a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click-ugc",
            "click-dm-[pgn]-ugcpic",
          ],
          "click": true
        },
        {
          "selector": ".post-sp > a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-spdetail",
          ],
          "click": true
        },
        {
          "selector": ".post-sp-btn a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-[pgn]-ugcpic-instantbuybutton",
          ],
          "click": true
        }
      ]
    },
    {
      "id": 7,
      "matches": [
        {
          "title": "单品标签",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/product\\/tag\\/[^\\/]+/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "g-c",
            "g-l",
            "d-domain-id",
            "d-business-unit"
          ],
          "values": [
            "dm-sp-buy",
            "pgn:sp[pgn]",
            "sp",
            "dm"
          ]
        },
        {
          "selector": ".dm-sp-card-buy, .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-[pgn]-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": "#sp_keyword",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-search"
          ],
          "click": true
        },
        {
          "selector": ".cn_history_data_list li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-searchrecommend-searchhistory"
          ],
          "click": true
        },
        {
          "selector": ".history_data_list li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-searchrecommend-inputmatch"
          ],
          "click": true
        }
      ]
    },
    {
      "id": 8,
      "matches": [
        {
          "title": "单品最热/最新/分类/品牌/商家/聚合/搜索",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/product\\/(new|cate|aggregate|s)(\\/[^\\/]+|$)/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "g-c",
            "g-l",
            "d-domain-id",
            "d-business-unit"
          ],
          "values": [
            "dm-sp-click",
            "pgn:sp[pgn]",
            "sp",
            "dm"
          ]
        },
        {
          "selector": ".mbx a",
          "title": "面包屑",
          "click": true
        },
        {
          "selector": ".product-sp-area .dm-sp-card-left",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-popup"
          ],
          "click": true
        },
        {
          "selector": ".product-sp-area .dm-sp-card-buy, .product-sp-area .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-[pgn]-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": "#sp_keyword",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-search"
          ],
          "click": true
        },
        {
          "selector": ".cn_history_data_list li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-searchrecommend-searchhistory"
          ],
          "click": true
        },
        {
          "selector": ".history_data_list li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-searchrecommend-inputmatch"
          ],
          "click": true
        },
        {
          "selector": "#product-list-data .dm-sp-card-left",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[eventtag]-popup"
          ],
          "click": true
        },
        {
          "selector": "#product-list-data a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[eventtag]-spdetail"
          ],
          "click": true
        },
        {
          "selector": "#product-list-data .dm-sp-card-buy, #product-list-data a.dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "click-dm-[eventtag]-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".cate-query-filter-toggle",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-filter"
          ],
          "click": true
        },
        {
          "selector": ".product-list-main .pagination a",
          "title": "分页按钮",
          "names": ["g-a", "d-value"],
          "values": ["click-dm-[eventtag]-paging", "[c]"],
          "click": true
        },
        {
          "selector": ".cate-query-sort > span",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-[pgn]-sort"
          ],
          "click": true
        },
        {
          "selector": ".product-rank-nav, .product-rank-suffix",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-hotsp-title",
          ],
          "click": true
        },
        {
          "selector": ".cate-query-sort .needValid input",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-spsearchresult-dealswitch",
          ],
          "click": true
        },
      ]
    },
    {
      "id": 9,
      "matches": [
        {
          "title": "抢好货首页",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/product$/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "g-c",
            "g-l",
            "d-domain-id",
            "d-business-unit"
          ],
          "values": [
            "dm-sp-click",
            "pgn:dm-sp-home",
            "sp",
            "dm"
          ]
        },
        {
          "selector": "#sp_keyword",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-search"
          ],
          "click": true
        },
        {
          "selector": ".cn_history_data_list li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-searchrecommend-searchhistory"
          ],
          "click": true
        },
        {
          "selector": ".history_data_list li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-searchrecommend-inputmatch"
          ],
          "click": true
        },
        {
          "selector": "#sign_in_top",
          "title": "顶部登录",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          values: [
            "dm-header-click",
            "click-dm-header-login",
            "home"
          ],
          "click": true
        },
        {
          "selector": "#register_up_top",
          "title": "顶部注册",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          values: [
            "dm-header-click",
            "click-dm-header-register",
            "home"
          ],
          "click": true
        },
        {
          "selector": "a.top-nav-entrance-link",
          "title": "一级大分类",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-categoryhotsp-floatbar"
          ],
          "click": true
        },
        {
          "selector": ".product-pick-box[data-hot-type=hot] .product-pick-nav",
          "title": "好货排行榜 - 标题",
          "names": [
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "click-dm-sphome-hot-title",
            "home"
          ],
          "click": true
        },
        {
          "selector": ".product-pick-box[data-hot-type=soaring] .product-pick-nav",
          "title": "好货飙升榜 - 标题",
          "names": [
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "click-dm-sphome-sptrend-title",
            "home"
          ],
          "click": true
        },
        {
          "selector": ".product-filter-box .dm-sp-card-left",
          "title": "",
          "names": ["g-a"],
          "values": ["click-dm-sphome-category-sppopup"],
          "click": true
        },
        {
          "selector": ".product-filter-box a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-category-spdetail"
          ],
          "click": true
        },
        {
          "selector": ".product-pick-box[data-hot-type=hot] .dm-sp-card-left",
          "title": "",
          "names": ["g-a"],
          "values": ["click-dm-sphome-hot-sppopup"],
          "click": true
        },
        {
          "selector": ".product-pick-box[data-hot-type=hot] a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-hot-spdetail"
          ],
          "click": true
        },
        {
          "selector": ".product-pick-box[data-hot-type=hot] a.dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "click-dm-sphome-hot-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".product-pick-box[data-hot-type=soaring] .dm-sp-card-left",
          "title": "",
          "names": ["g-a"],
          "values": ["click-dm-sphome-sptrend-sppopup"],
          "click": true
        },
        {
          "selector": ".product-pick-box[data-hot-type=soaring] a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-sptrend-spdetail"
          ],
          "click": true
        },
        {
          "selector": ".product-pick-box[data-hot-type=soaring] a.dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "click-dm-sphome-sptrend-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".product-pick-suffix span, .product-pick-suffix a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-hot-all"
          ],
          "click": true
        },
        {
          "selector": ".product-topic-box .sp-scroll-nav-title",
          "title": "",
          "names": [
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "click-dm-sphome-topic-title",
            "home"
          ],
          "click": true
        },
        {
          "selector": ".product-topic-box .dm-sp-card-left",
          "title": "",
          "names": ["g-a"],
          "values": ["click-dm-sphome-topic-popup"],
          "click": true
        },
        {
          "selector": ".product-topic-box a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-topic-spdetail"
          ],
          "click": true
        },
        {
          "selector": ".product-topic-box a.dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "click-dm-sphome-topic-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".product-topic-more:not(.more)",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-topic-more"
          ],
          "click": true
        },
        {
          "selector": ".product-topic-more.more",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-topic-all"
          ],
          "click": true
        },
        {
          "selector": ".product-tag-box.ga-tag .product-tag-nav",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-hashtagtopic",
            "home"
          ],
          "click": true
        },
        {
          "selector": ".product-tag-box.ga-tag .product-tag-slider",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-hashtag"
          ],
          "click": true
        },
        {
          "selector": ".product-tag-box.ga-tag-brand .product-tag-nav",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-hashtagtopic-brand",
            "home"
          ],
          "click": true
        },
        {
          "selector": ".product-tag-box.ga-tag-brand .product-tag-slider",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-hashtag-brand"
          ],
          "click": true
        },
        {
          "selector": ".product-tag-box.ga-tag-store .product-tag-nav",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-hashtagtopic-merchant",
            "home"
          ],
          "click": true
        },
        {
          "selector": ".product-tag-box.ga-tag-store .product-tag-slider",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-hashtag-merchant"
          ],
          "click": true
        },
        {
          "selector": ".product-tag-box.ga-tag-category .product-tag-nav",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-hashtagtopic-category",
            "home"
          ],
          "click": true
        },
        {
          "selector": ".product-tag-box.ga-tag-category .product-tag-slider",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-hashtag-category"
          ],
          "click": true
        },
        {
          "selector": ".product-aggr-box .product-aggr-nav",
          "title": "",
          "names": [
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "click-dm-sphome-aggregateugc-title",
            "home"
          ],
          "click": true
        },
        {
          "selector": ".product-aggr-box .product-aggr-post a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-aggregateugc-spdetail"
          ],
          "click": true
        },
        {
          "selector": ".product-aggr-box .sp-scroll-nav-title",
          "title": "",
          "names": [
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "click-dm-sphome-aggregatesp-title",
            "home"
          ],
          "click": true
        },
        {
          "selector": ".product-aggr-box .dm-sp-card-left",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-aggregatesp-popup"
          ],
          "click": true
        },
        {
          "selector": ".product-aggr-box a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-aggregatesp-spdetail"
          ],
          "click": true
        },
        {
          "selector": ".product-aggr-box a.dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "click-dm-sphome-aggregatesp-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".product-filter-box .dm-sp-card-buy, .product-filter-box .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-sphome-category-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".product-topic-box .dm-sp-card-buy, .product-topic-box .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-sphome-topic-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".product-pick-box .dm-sp-card-buy, .product-pick-box .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-sphome-hot-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".product-aggr-box .dm-sp-card-buy, .product-aggr-box .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-sphome-aggregatesp-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".product-aggr-box .post-sp-btn a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-sphome-aggregateugc-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".product-seen-box .dm-sp-card-left",
          "title": "",
          "names": ["g-a"],
          "values": ["click-dm-sphome-viewedsp-sppopup"],
          "click": true
        },
        {
          "selector": ".product-seen-box a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-sphome-viewedsp-spdetail"
          ],
          "click": true
        },
        {
          "selector": ".product-seen-box .dm-sp-card-buy, .product-seen-box .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-sphome-viewedsp-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".logo",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-dealmoonlogo"
          ],
          "click": true
        },
        {
          "selector": ".banner .title",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-splogo"
          ],
          "click": true
        },
        {
          "selector": ".product-pick-prev",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-hot-left"
          ],
          "click": true
        },
        {
          "selector": ".product-pick-next",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-sphome-hot-right"
          ],
          "click": true
        },
      ]
    },
    {
      "id": 10,
      "matches": [
        {
          "title": "全部专题",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/product\\/event$/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "g-c",
            "g-l",
            "d-domain-id",
            "d-business-unit"
          ],
          "values": [
            "dm-sp-click",
            "pgn:spalltopic",
            "sp",
            "dm"
          ]
        },
        {
          "selector": ".product-event-item > a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-alltopic-topicdetail"
          ]
        },
        {
          "selector": ".product-event-sp-hover, .product-event-item-group a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-alltopic-spdetail"
          ]
        },
      ]
    },
    {
      "id": 12,
      "matches": [
        {
          "title": "top榜页",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/product\\/rank$/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": ".rank-cate-scroll li",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-hotsp-floatbar",
          ],
          "click": true
        },
        {
          "selector": ".product-rank-prev",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-hotsp-left",
          ],
          "click": true
        },
        {
          "selector": ".product-rank-next",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-hotsp-right",
          ],
          "click": true
        },
        {
          "selector": ".product-rank .dm-sp-card-left",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-hotsp-sppopup",
            "sp"
          ],
          "click": true
        },
        {
          "selector": ".product-rank a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-hotsp-spdetail",
            "sp"
          ],
          "click": true
        },
        {
          "selector": ".product-rank .dm-sp-card-buy, .product-rank .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-hotsp-directbuybutton",
            "sp"
          ],
          "click": true
        },
      ]
    },
    {
      "id": 13,
      "matches": [
        {
          "title": "三级分类/品牌/商家",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/product\\/(cate-new|brand|store)(\\/[^\\/]+|$)/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-pgn",
            "d-business-unit",
            "d-domain-id",
            "g-l",
          ],
          "values": [
            "hashtagdetail",
            "dm",
            "[pgn]",
            "pgn:sp[pgn]",
          ]
        },
        {
          "selector": ".product-fans .post-hover > a, .product-fans .post-sp > a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-[pgn]-spdetail "
          ],
          "click": true
        },
        {
          "selector": ".product-fans .post-sp-btn > a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-[pgn]-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": ".dm-sp-card-left",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-[pgn]-sppopup",
            "sp"
          ],
          "click": true
        },
        {
          "selector": "a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-[pgn]-spdetail",
            "sp"
          ],
          "click": true
        },
        {
          "selector": ".dm-sp-card-buy, .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-[pgn]-directbuybutton",
            "sp"
          ],
          "click": true
        },
        {
          "selector": ".product-list-prev, .product-fans-post-prev",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-[pgn]-left",
          ],
          "click": true
        },
        {
          "selector": ".product-list-next, .product-fans-post-next",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-[pgn]-right",
          ],
          "click": true
        }
      ]
    },
    {
      "id": 15,
      "matches": [
        {
          "title": "折扣搜索页",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/top\\//i",
          "query": "",
        }
      ],
      "defines": [
        {
          "selector": ".dm-sp-card-buy, .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-spsearchresult-directbuybutton",
          ],
          "click": true
        },

        {
          "selector": "#dealsList .h-left-content .h-filter-head span",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-search-click",
            "click-dm-search-result-tab",
          ],
          "click": true
        },

        {
          "selector": ".postGuide .moment-link",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-search-click",
            "click-dm-search-result-relatedugc-all",
          ],
          "click": true
        },

        {
          "selector": "#search-recommend-words .dm-search-recommend-click",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-search-click",
            "click-dm-search-result-relatedword",
          ],
          "click": true
        },

        {
          "selector": ".search_deal_sp .criteo_product_item",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-search-click",
            "click-dm-search-result-relatedword",
          ],
          "click": true
        },


        {
          "selector": ".sp-subject-ad .sp-subject-slide:not(:last-child)",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-dealsearchresult-searchsp-spdetail",
          ],
          "click": true
        },
        {
          "selector": ".sp-subject-ad .sp-subject-slide:not(:last-child)",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-dealsearchresult-searchsp-spdetail",
          ],
          "click": true
        },
        {
          "selector": ".sp-subject-ad .position_title",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-dealsearchresult-searchsp-title",
          ],
          "click": true
        },
        {
          "selector": ".sp-subject-ad .sp-subject-slide:last-child",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-dealsearchresult-searchsp-more",
          ],
          "click": true
        },
        {
          "selector": ".sp-subject-ad .sp-left-btn-search",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-dealsearchresult-searchsp-title-left",
          ],
          "click": true
        },
        {
          "selector": ".sp-subject-ad .sp-right-btn-search",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-dealsearchresult-searchsp-title-right",
          ],
          "click": true
        },
        {
          "selector": ".sp-show-filter .needValid input",
          "title": "",
          "names": [
            "d-pgn",
            "g-c",
            "g-a",
            "d-domain-id",
            "d-business-unit",
            "g-l"
          ],
          "values": [
            "spsearchresult",
            "dm-search-click",
            "sp",
            "dm",
            "click-dm-search-sp-dealswitch",
            "pgn:[pgn]"
          ],
          "click": true
        }
      ]
    },
    {
      "id": 16,
      "matches": [
        {
          "title": "大家都在抢（大分类全部页）",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/product\\/(hot-cate|hot-all|trend|hot-brand|hot-store)/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-business-unit",
            "d-domain-id",
            "g-l",
          ],
          "values": [
            "dm",
            "sp",
            "pgn:[pgn]",
          ]
        },
        {
          "selector": ".dm-sp-card-left",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-categoryhotsp-sppopup",
          ],
          "click": true
        },
        {
          "selector": "a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-categoryhotsp-spdetail",
          ],
          "click": true
        },
        {
          "selector": ".dm-sp-card-buy, .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-buy",
            "buy-dm-[eventname]-directbuybutton",
          ],
          "click": true
        },
        {
          "selector": ".rank-cate-scroll a",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-sp-click",
            "click-dm-categoryhotsp-floatbar",
          ],
          "click": true
        },
      ]
    },
    {
      "id": 11,
      "matches": [
        {
          "title": "抢好货分类列表页",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/product\\/[^\\/]+/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-pgn",
            "d-business-unit",
            "d-domain-id",
            "g-l",
          ],
          "values": [
            "dm-sp-category",
            "dm",
            "sp",
            "pgn:[pgn]",
          ]
        },
        {
          "selector": ".mbx a",
          "title": "面包屑",
          "click": true
        },
        {
          "selector": ".product-top-desc .expand-button",
          "title": "分类描述展开/收起按钮",
          "names": ["g-a"],
          "values": ["click-dm-spcategory-description-fold"],
          "click": true
        },
        {
          "selector": ".product-list-main .pagination a",
          "title": "分页按钮",
          "names": ["g-a", "d-value"],
          "values": ["click-dm-spcategory-paging", "[c]"],
          "click": true
        },
        {
          "selector": ".product-fans .post-hover > a, .product-fans .post-sp > a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-spcategory-spdetail"
          ],
          "click": true
        },
        {
          "selector": ".product-fans .post-sp-btn > a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-sp-buy",
            "click-buy-dm-spcategory-directbuybutton"
          ],
          "click": true
        },
        {
          "selector": "#product-list-data .dm-sp-card-left",
          "title": "",
          "names": ["g-c", "g-a", "d-domain-id"],
          "values": ["dm-sp-click", "click-dm-spcategory-sppopup", "sp"],
          "click": true
        },
        {
          "selector": "#product-list-data a.dm-sp-card-detail-button",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-click",
            "click-dm-spcategory-spdetail",
            "sp"
          ],
          "click": true
        },
        {
          "selector": "#product-list-data .dm-sp-card-buy, #product-list-data .dm-sp-card-direct-link",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-domain-id"
          ],
          "values": [
            "dm-sp-buy",
            "click-buy-dm-spcategory-directbuybutton",
            "sp"
          ],
          "click": true
        }
      ],
    },
    {
      "id": 17,
      "matches": [
        {
          "title": "新商家聚合首页",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/stores$/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-pgn",
            "d-business-unit",
            "d-domain-id",
            "g-l",
            "g-c"
          ],
          "values": [
            "storelist",
            "dm",
            "store",
            "pgn:[pgn]",
            "dm-store-list"
          ]
        },
        {
          "selector": ".store-recommend > li",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-recommend-[i]"
          ],
          "click": true,
        },
        {
          "selector": ".store-ad",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-advertisement-1"
          ],
          "click": true,
        },
        {
          "selector": ".store-list-search-list li",
          "title": "",
          "names": [
            "g-a",
            "d-store-name"
          ],
          "values": [
            "click-dm-list-search",
            "[c]"
          ],
          "click": true,
        },
        {
          "selector": ".store-list-cate [data-scroll-to]",
          "title": "",
          "names": [
            "g-a",
            "d-category"
          ],
          "values": [
            "click-dm-list-category",
            "[c]"
          ],
          "click": true,
        },
        {
          "selector": ".store-list-cate [data-scroll-to]",
          "title": "",
          "names": [
            "g-a",
            "d-category"
          ],
          "values": [
            "click-dm-list-category",
            "[c]"
          ],
          "click": true,
        }
      ]
    },
    {
      "id": 18,
      "matches": [
        {
          "title": "新商家聚合详情页",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/stores\\/[^\\/]+$/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-pgn",
            "d-business-unit",
            "d-domain-id",
            "g-l",
            "g-c"
          ],
          "values": [
            "storedetail",
            "dm",
            "store",
            "pgn:[pgn]",
            "dm-store-detail"
          ]
        },
        {
          "selector": ".search-cont-wrap .mlist .left-cnt a, .search-cont-wrap .mlist .right-cnt .zoom-title",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-store-click",
            "click-dm-store-deals-dealdetail"
          ],
          "click": true
        },
        {
          "selector": ".search-cont-wrap .mlist .btn-buy[rel*='nofollow']",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-store-buybutton"
          ],
          "click": true
        },
        {
          "selector": ".search-cont-wrap .mlist .dpc a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-store-sp"
          ],
          "click": true
        },
        {
          "selector": ".search-cont-wrap .mlist .mbody ul a, .search-cont-wrap .mlist .mbody p a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-store-content"
          ],
          "click": true
        },
        {
          "selector": ".search-cont-wrap .mlist .mbody .sp-in-deal a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-store-post"
          ],
          "click": true
        },
        {
          "selector": ".store-info-logo",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-store-buy",
            "buy-dm-storedetail-logo"
          ],
          "click": true,
        },
        {
          "selector": ".store-info-content a",
          "title": "",
          "names": [
            "g-c",
            "g-a",
          ],
          "values": [
            "dm-store-buy",
            "buy-dm-storedetail-title"
          ],
          "click": true,
        },
        {
          "selector": ".store-info-content-control-search em",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-detail-search"
          ],
          "click": true,
        },
        {
          "selector": ".store-cate a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-tab-[type]"
          ],
          "click": true,
        },
        {
          "selector": ".store-cate a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-tab-[type]"
          ],
          "click": true,
        },
        {
          "selector": ".store-filter--deal .last-options",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-deals-hide"
          ],
          "click": true,
        },
        {
          "selector": ".store-filter--coupon .last-options",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-coupons-hide"
          ],
          "click": true,
        },
        {
          "selector": ".area_right .ggw",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-advertisement-[i]"
          ],
          "click": true,
        },
        // {
        //   "selector": ".sp-hot-title a",
        //   "title": "",
        //   "names": [
        //     "g-a"
        //   ],
        //   "values": [
        //     "click-dm-recommend-products-more"
        //   ],
        //   "click": true,
        // },
        // {
        //   "selector": ".sp-hot-content a",
        //   "title": "",
        //   "names": [
        //     "g-a"
        //   ],
        //   "values": [
        //     "click-dm-recommend-products"
        //   ],
        //   "click": true,
        // },
        {
          "selector": ".search-cont-sps a",
          "title": "单品 tab 下单品详情",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-store-click",
            "click-dm-store-spdetail"
          ],
          "click": true,
        },
        {
          "selector": ".dm-sg-search-list a.gctype-ugc",
          "title": "攻略 tab 下详情 - UGC",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-store-click",
            "click-dm-store-ugcguidedetail"
          ],
          "click": true,
        },
        {
          "selector": ".dm-sg-search-list a.gctype-pgc",
          "title": "攻略 tab 下详情 - PGC",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-store-click",
            "click-dm-store-pgcguidedetail"
          ],
          "click": true,
        },
        {
          "selector": ".dm-post-waterfall a[data-dmt-d-post-id]",
          "title": "笔记 tab 下详情",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-store-click",
            "click-dm-store-postdetail"
          ],
          "click": true,
        },
        {
          "selector": ".store-zhongce-activity-wrapper a[data-dmt-d-function-id]",
          "title": "众测 tab 下众测详情",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-store-click",
            "click-dm-store-reviewdetail"
          ],
          "click": true,
        },
        {
          "selector": ".store-zhongce-guide-wrapper a.gctype-ugc",
          "title": "众测 tab 下评测长文章 - 长文章 - UGC",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-store-click",
            "click-dm-store-review-ugcguidedetail"
          ],
          "click": true,
        },
        {
          "selector": ".store-zhongce-guide-wrapper a.gctype-pgc",
          "title": "众测 tab 下评测长文章 - 长文章 - PGC",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-store-click",
            "click-dm-store-review-pgcguidedetail"
          ],
          "click": true,
        },
        {
          "selector": ".store-zhongce-guide-wrapper a[data-dmt-d-post-id]",
          "title": "众测 tab 下评测长文章 - 笔记",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-store-click",
            "click-dm-store-review-postdetail"
          ],
          "click": true,
        },
        {
          "selector": ".zhongce-right-activiy title a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-recommend-reviews-more"
          ],
          "click": true,
        },
        {
          "selector": ".zhongce-right-activiy .box a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-recommend-reviews-1"
          ],
          "click": true,
        },
        {
          "selector": ".zhongce-right-guide .box a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-recommend-reviews-2"
          ],
          "click": true,
        },
        {
          "selector": ".box_gonglue .gonglue_more",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-recommend-guides-more"
          ],
          "click": true,
        },
        {
          "selector": ".box_gonglue .gonglue_cont a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-recommend-guides"
          ],
          "click": true,
        },
        {
          "selector": "#showPost .box a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-recommend-moments"
          ],
          "click": true,
        },
        {
          "selector": "#hot_deal .title a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-recommend-relative-more"
          ],
          "click": true,
        },
        {
          "selector": "#hot_deal .store_list a",
          "title": "",
          "names": [
            "g-a"
          ],
          "values": [
            "click-dm-recommend-relative"
          ],
          "click": true,
        },
      ]
    },
    require('./www/brands/index'),
    require('./www/brands/list'),
    require('./www/brands/detail'),
    require('./www/guide/index'),
    require('./www/profile/index'),
    require('./www/popular/soaring'),
    require('./www/popular/index'),
    {
      "id": 25,
      "matches": [
        {
          "title": "信用卡首页",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^\\/credit-cards$/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-pgn",
            "d-business-unit",
            "d-domain-id",
            "g-l"
          ],
          "values": [
            "creditcard",
            "dm",
            "[pgn]",
            "pgn:[pgn]"
          ]
        },
        {
          "selector": ".credit_list .credit_item_aside .credit_item_img",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[pgn]-buy",
            "buy-dm-[pgn]-cover"
          ],
          "click": true
        },
        {
          "selector": ".credit_list .credit_item_aside .credit_item_btn_apply",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[pgn]-buy",
            "buy-dm-[pgn]-buybutton"
          ],
          "click": true
        },
        {
          "selector": ".credit_list .credit_item_content a[rel*='nofollow']",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[pgn]-buy",
            "buy-dm-[pgn]-content"
          ],
          "click": true
        },
      ]
    },
    {
      "id": 26,
      "matches": [
        {
          "title": "信用卡详情",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^\\/credit-cards\\/[\\w-]+\\/\\d+\\.html/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-pgn",
            "d-business-unit",
            "d-domain-id",
            "g-l"
          ],
          "values": [
            "creditcard",
            "dm",
            "[pgn]",
            "pgn:[pgn]"
          ]
        },
        {
          "selector": ".credit_item_detail .credit_item_aside .credit_item_img",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[pgn]-buy",
            "buy-dm-[pgn]detail-cover"
          ],
          "click": true
        },
        {
          "selector": ".credit_item_detail .credit_item_aside .credit_item_btn_apply",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[pgn]-buy",
            "buy-dm-[pgn]detail-buybutton"
          ],
          "click": true
        },
        {
          "selector": ".credit_item_detail .credit_item_content a[rel*='nofollow']",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[pgn]-buy",
            "buy-dm-[pgn]detail-content"
          ],
          "click": true
        }
      ]
    },
    {
      "id": 27,
      "matches": [
        {
          "title": "众测详情页",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^\\/product-reviews\\/\\d+/i",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-pgn",
            "d-business-unit",
            "d-domain-id",
            "g-l"
          ],
          "values": [
            "zhongce",
            "dm",
            "[pgn]",
            "pgn:[pgn]"
          ]
        },
        {
          "selector": ".public-test-detail .btn.buy",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-zhongce-buy",
            "buy-dm-[pgn]detail-buybutton"
          ],
          "click": true
        },
      ],
    },
    //普通爆料
    {
      "id": 28,
      "matches": [
        {
          "title": "普通爆料(详情页)",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^\\/baoliao\\/\\d+/",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-business-unit",
            "g-l"
          ],
          "values": [
            "dm",
            "pgn:baoliaodetail"
          ]
        },
        {
          "selector": "a.btn-buy",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[dt]-buy",
            "buy-dm-[dt]detail-buybutton"
          ],
          "click": true
        },
        {
          "selector": ".article_mlist .p-left .snapshot",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[dt]-buy",
            "buy-dm-[dt]detail-cover"
          ],
          "click": true
        },
        {
          "selector": ".article_mlist .p-right .zoom-title",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[dt]-buy",
            "buy-dm-[dt]detail-title"
          ],
          "click": true
        },
        {
          "selector": ".article_mlist .p-right .minfo_article .store_name",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[dt]-buy",
            "buy-dm-[dt]detail-store"
          ],
          "click": true
        },
        {
          "selector": ".article_mlist .sp-in-deal a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[dt]-buy",
            "buy-dm-[dt]detail-post"
          ],
          "click": true
        },
        {
          "selector": ".article_mlist .zoom-detail .minfor a[rel*='nofollow']",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[dt]-buy",
            "buy-dm-[dt]detail-content"
          ],
          "click": true
        },
        {
          "selector": "#spBox .tw a, .dm-cmt-sp a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[dt]-buy",
            "buy-dm-[dt]detail-sp"
          ],
          "click": true
        },
        {
          "selector": ".link_icon_buy",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-[dt]-buy",
            "buy-dm-[dt]detail-floatingbuybutton"
          ],
          "click": true
        },
      ]
    },
    // 热门折扣
    {
      "id": 29,
      "matches": [
        {
          "title": "热门折扣",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?\\/hot-picks/",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "#dealsList .classify_ele a, #dealsList .classify_inf .check_box",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-pgn",
            "d-domain-id",
            "d-business-unit",
            "g-l",
            "d-prefix",
          ],
          "values": [
            "dm-category-click",
            "click-dm-category-selectbutton",
            "category",
            "[pgn]",
            "dm",
            "png:[pgn]",
            "HOT|",
          ],
          "click": true
        },
      ],
    },
    {
      "id": 30,
      "matches": [
        {
          "title": "爆料首页",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/baoliao/",
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            "d-business-unit",
            "d-domain-id",
          ],
          "values": [
            "dm",
            "baoliao",
          ]
        },
      ],
    },
    // 分类页放在最后
    {
      "id": 31,
      "matches": [
        {
          "title": "折扣频道页（分类页）",
          "host": "/^www\\.([a-z0-9]+\\.)?(dealmoon|dazhe)\\.[a-z\\.]{2,10}/i",
          "path": "/^(\\/(cn|bg|en))?(\\/[a-z]+(-[a-z]+)*)+$/",
          "excludes": [
            "/product-reviews",
            "/product-reviews/*",
            "/marketplace",
            "/marketplace/*"
          ],
          "query": "",
          "screenName": ""
        }
      ],
      "defines": [
        {
          "selector": "body",
          "title": "",
          "names": [
            // "d-pgn",
            "d-business-unit",
            "d-domain-id",
            // "g-l"
          ],
          "values": [
            // "home",
            "dm",
            "deal",
            // "pgn:[pgn]"
          ]
        },
        {
          "selector": "#dealsList .mlist .btn-buy",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-[pgn]-feed-buybutton"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .dpc a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-[pgn]-feed-sp"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .mbody ul a, #dealsList .mlist .mbody p a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-[pgn]-feed-content"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist .mbody .sp-in-deal a",
          "title": "",
          "names": [
            "g-c",
            "g-a"
          ],
          "values": [
            "dm-deal-buy",
            "buy-dm-[pgn]-feed-post"
          ],
          "click": true
        },
        {
          "selector": "#dealsList .mlist[data-ad-type=bask_strategy] .tags a",
          "title": "",
          "names": [
            "g-c",
            "g-a",
            "d-value",
          ],
          "values": [
            "dm-ugc-promo-click",
            "click-dm-[pgn]-promo-[adno]",
            "[c]"
          ],
          "click": true
        }
      ]
    },
  ],
  "lastUpdate": 1546055450344
}
