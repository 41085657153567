/**
 * 定义公共常量
 */
const TRK_PREFIX = 'data-dmt';
const CACHE_PREFIX = '__dmt__';

module.exports = {
  // 统计发送类型
  TYPE_ALL: 'ALL',
  TYPE_LOG: 'LOG',
  TYPE_GA: 'GA',

  // 日志接口地址
  LOG_SERVER: {
    US: '//api2.apps.dealmoon.com/log/cfe538a7', //北美站埋点日志
    AU: '//api2.dealmoon.com.au/log/cfe538a7', // 澳洲站埋点日志
    CA: '//api2.dealmoon.ca/log/cfe538a7', //加拿大站埋点日志
    UK: '//api2.dealmoon.co.uk/log/cfe538a7', //英国站埋点日志
    FR: '//api2.dealmoon.fr/log/cfe538a7', //法国站埋点日志
    DE: '//api2.dazhe.de/log/cfe538a7' //德国站埋点日志
  },

  // GA ID
  GA_ID: {
    US: 'UA-16853686-2', // 北美站
    AU: 'UA-102398126-3', // 澳洲站
    CA: 'UA-16853686-12', // 加拿大站
    UK: 'UA-104394585-2', // 英国站
    FR: 'UA-16853686-35', // 法国站
    DE: 'UA-16853686-37', // 德国站
    TEST: 'UA-67307525-2' // 测试GA ID 
  },

  // 事件触发类型
  EVENT_CLICK: 'click',

  // 统计节点属性前缀
  TRK_ATTR_PREFIX: TRK_PREFIX,
  TRK_ATTR_PREFIX_SUBSTR_LENGTH: TRK_PREFIX.length + 1,

  // 需要发送埋点属性
  TRK_ATTR_ACTION: TRK_PREFIX,

  /**
   * 定义埋点为声明式埋点
   * 在 Dom 上按会将埋点数据设置在属性中，当一个事件触发时，总自身开始向上查找最近的含有下列属性的节点，取出对应值。
   * 无痕埋点实际就是加载页面以后，也将值设置在对应的dom节点上。
   * 埋点的前缀都为 data-dmt，意思是 dealmoon track 。
   * 埋点分为 Log 和 GA (分别简写为 log 和 ga)。
   * 
   * 1. 定义是否埋点 (click / impression / pv)
   * 从当前元素，开始向上查找，如果找到了 data-dmt-click / data-dmt-view 说明该节点是需要发送埋点的。
   * data-dmt（Click 点击埋点）：当 DOM 有该属性则为需要点击埋点，默认会有 Log 和 GA，如果只想使用某一个埋点，请设置 data-dmt-ga data-dmt-log
   * data-dmt-im（Impression 浏览埋点）：当 DOM 有该属性则为需要浏览埋点，Impression会自动收集放入队列，择机发送。（@todo 未实现）
   * PV（PV 埋点）：PV 埋点包括 Log 和 GA 的 PV 埋点，需要通过 dmTrack API 调用。
   * 页面跳出和留存时间埋点：自动处理，但是目前还没有实现。
   * 
   * 2. 定义 Page[页面] Model[模块] Index[位置] 的方法（简化版的 SPM）
   * data-dmt-s-p = 'home'  // 定义 Page 
   * data-dmt-s-m = 'hot_list_ad' // 定义 Model
   * data-dmt-s-i = '6' // 定义 Index 
   * 
   * 3. 定义主要埋点参数
   * 3.1 定义 Log (Topic: className/name)
   * data-dmt-l-c = 'biz.hot_deal'
   * data-dmt-l-n = 'deal_click'
   * 
   * 3.2 定义 GA (EventCategory/EventAction/EventLabel)
   * 注意：目前这里和 Log 不一致，后续目标：
   *   a. EventCategory 和 Log 的 className 一致；
   *   b. EventAction 和 Log 的 name 一致；
   *   c. 简化 EventLabel ，因为大部分数据都在 Dimension 中有了。
   * data-dmt-g-c = 'dm-sp-position-click'
   * data-dmt-g-a = 'click-dm-new-position-19'
   * data-dmt-g-l = 'type:sp|id:2529312|random:no|title:Faye mini 斜挎包|png:dealfeed|yh:0|pf:web'
   * 
   * 3. 定义埋点数据 (data)
   * 埋点数据将作为 Log 的 data 和 GA 的 dimension 参数。数据定义在 data-dmt-d-* 参数中，数据字段为蛇形，dmTrack 自动适配。
   * data-dmt-d-deal_id    = '622748'
   * data-dmt-d-sp_id      = '209527'
   * data-dmt-d-keywords   = 'Nike'
   * 
   * 4. 定义转换数据 (evar)
   * 转换数据将作为 Log 下个页面的转换参数（GA 忽略）。数据定义在 data-dmt-e-* 参数中，数据字段为蛇形，dmTrack 自动适配。
   * data-dmt-e-deal_id   = '724124'
   * data-dmt-e-sp_id     = '204221'
   * data-dmt-e-keywords  = 'Jet'
   * 
   * 5. 定义转换数据（rip）
   * 如果当前页面定义了 SPM，当前页面跳转出去的链接自动会计入下个页面的 rip
   * 
   * 转换数据原理：
   * 转换数据主要有 rip 和 eVar， 存取方式为 localstorage，这样跳转到下一个页面时从 localstorage 中取值。
   * rip 是当前页面触发事件的发送的 SPM 参数，会作为下一个页面的（rip/rip_value/rip_position），
   * evar 是转换数据 
   * localStorage 为一个 FILO 数组，命名为 dm_dmt ，最大支持 20 条记录，缓存 1 小时。
   * 最新记录的 rip 放最后，在页面「加载完成 DOMContentLoaded」或「激活」时就从该数组后往前查找（处理激活是为了防止用户在多个页面切换时，记录错乱）
   * 直到找到 document.referrer 和 key 配的一条
   * 取出 rip/rip_value/rip_position 后， 分别存入 cookie（exec track 使用），和存入 Log 参数。
   * dm_dmt 被 JSON.stringify 后格式如下：
   * [
   *  {key: '/home.html': rip: 'home.hot_list_ad.6', evar: {}},
   *  {key: '/detail.html': rip: 'xxoo'},
   *  {key: '/home.html': rip: ''},
   * ]
   * __dm_dmt__expires__ 为当前 时间戳 + 缓存时间，如1538296770225。每次存取 dm_rip 时会先检查是否过期，如果过期并更新该时间
   * 
   */
  TRK_ATTR_MAP: {
    // SPM
    's-p': ['log.fromPage', 'cache.rip.rip'],
    's-m': ['log.fromObj', 'cache.rip.rip_value'],
    's-i': ['log.fromObjIndex', 'cache.rip.rip_position'],
    // Log
    'l-c':   'log.classname',
    'l-n':   'log.name',
    'l-cmd': 'log.command',
    // GA
    'g-c': 'ga.eventCategory',
    'g-a': 'ga.eventAction',
    'g-l': 'ga.eventLabel',
    // Log Data & GA Dimension 见 custom dimensions 与 index 映射表
    'd-udid':             ['ga.dimension1'],  // ???
    'd-deal-id':          ['log.data.dealId', 'log.dealId', 'ga.dimension2'],
    'd-category':         ['log.data.category', 'ga.dimension3'],
    'd-banner-id':        ['ga.dimension4'],
    'd-value':            ['ga.dimension5'],
    'd-sku-id':           ['ga.dimension6'],
    'd-post-id':          ['log.data.postId', 'ga.dimension7'],
    'd-store-name':       ['ga.dimension8'],
    'd-sp-id':            ['log.data.itemId', 'log.itemId'],
    'd-dp-id':            ['ga.dimension9'], // GA 中的 dpId 和 log 中的单品 ID 不一致，这里单独提出来，GA中的DP ID 格式为 单品ID-单品名称
    'd-page':             ['ga.dimension10'], // ???
    'd-tag':              ['log.data.tag', 'ga.dimension11'],
    'd-prev-page':        ['ga.dimension12'], // ???
    'd-guide-id':         ['log.data.guideID', 'ga.dimension13'],
    'd-editor-id':        ['ga.dimension14'],
    'd-channel-id':       ['ga.dimension15'],
    'd-uid':              ['ga.dimension16'], // ??? 
    'd-business-unit':    ['ga.dimension17'],
    'd-local-metro-city': ['ga.dimension18'], 
    'd-domain-id':        ['ga.dimension19'], 
    'd-sub-category-id':  ['ga.dimension20'], 
    'd-function-id':      ['ga.dimension21'], 
    'd-language':         ['ga.dimension22'],
    'd-guide-category':   ['ga.dimension28'], // 攻略分类
    'd-guide-title':      ['ga.dimension25'], // 攻略title
    'd-type':             ['ga.dimension29'], // type
    'd-post-category':    ['ga.dimension30'],
    
     // 这里转换数据有一些小驼峰适配，特殊处理一下
    'e-deal-id':   ['cache.evar.dealId'],
    'e-sp-id':     ['cache.evar.itemId'],
    'e-post-id':   ['cache.evar.postId'],
    'e-guide-id':  ['cache.evar.guideId'],
    // 其他数据字段
    '_':  attr => {
      var [, a1, a2] = attr.match(/^([^\-]+)-(.+)$/) || [];
      switch(a1) {
        case 'd': return `log.data.${a2}`;
        case 'e': return `cache.evar.${a2}`;
        // 发送 gaLabel 时需要动态添加一些参数
        default : return '';
      }
    }
  },

  // 属性和数据默认数据占位符配置
  NAME_INDEX: 's-i',
  VALUE_INDEX: '[i]',
  VALUE_INDEX_REGEX: /\[i\]/, // index 占位正则
  VALUE_CONTENT: '[c]',
  VALUE_CONTENT_REGEX: /\[c\]/, // 内容占位正则，一般放在value中
  VALUE_HOLDER_REGEX: /\[([^\]]+)\]/, // 通用数据占位正则
  VALUE_DATA_REGEX: /^d-/, // 数据属性正则
  
  // 转换数据缓存配置
  CACHE_KEY_DATA: `${CACHE_PREFIX}stack__`, // 缓存数据 Key
  CACHE_KEY_EXPIRES: `${CACHE_PREFIX}expires__`, // 缓存过期 Key
  CACHE_MAX_TIME: 3600000, // 最大缓存时间
  CACHE_MAX_LENGTH: 20, // 最大缓存长度
};
