module.exports = {
  id: 23,
  matches: [
    {
      title: '个人页',
      host: '/^www.([a-z0-9]+.)?(dealmoon|dazhe).[a-z.]{2,10}/i',
      path: '/^(\\/(cn|bg|en))?\\/[\\s\\S]*\\-profile/i',
      query: '',
      screenName: ''
    }
  ],
  defines: [
    {
      selector: 'body',
      title: '',
      names: ['d-pgn', 'd-business-unit', 'd-domain-id', 'g-l', 'g-c'],
      values: ['personal', 'dm', 'personal', 'pgn:[pgn]', 'dm-personal-click'],
      click: true
    },
    // tab点击
    {
      selector: '.profile-category-moment',
      title: '',
      names: ['g-a'],
      values: ['click-dm-personal-news'],
      click: true
    },
    {
      selector: '.profile-category-guide',
      title: '',
      names: ['g-a'],
      values: ['click-dm-personal-guides'],
      click: true
    },
    {
      selector: '.profile-category-post',
      title: '',
      names: ['g-a'],
      values: ['click-dm-personal-moments'],
      click: true
    },
    {
      selector: '.profile-category-disclosure',
      title: '',
      names: ['g-a'],
      values: ['click-dm-personal-deals'],
      click: true
    },
    {
      selector: '.profile-category-comment',
      title: '',
      names: ['g-a'],
      values: ['click-dm-personal-comments'],
      click: true
    },
    {
      selector: '.profile-category-userDish',
      title: '',
      names: ['g-a'],
      values: ['click-dm-personal-recommends'],
      click: true
    },
    // 关注按钮点击
    {
      selector: '.dm-sg-focus',
      title: '',
      names: ['g-a'],
      values: ['click-dm-personal-follow'],
      click: true
    },
    // 分享按钮点击
    {
      selector: '#profile-share',
      title: '',
      names: ['g-a'],
      values: ['click-dm-personal-share'],
      click: true
    },
    // 个人页广告
    {
      selector: '.profile-ad',
      title: '',
      names: ['g-a'],
      values: ['click-dm-personal-advertisements'],
      click: true
    },
    // 用户信息点击
    {
      selector: '.follow .user-info a',
      title: '',
      names: ['g-a'],
      values: ['click-dm-personal-information'],
      click: true
    },
  ]
}
