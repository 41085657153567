module.exports = {
  id: 24,
  matches: [
    {
      title: '排行榜',
      host: '/^www.([a-z0-9]+.)?(dealmoon|dazhe).[a-z.]{2,10}/i',
      path: '/^(/(cn|bg|en))?/popular-soaring-list$/i',
      query: '',
      screenName: ''
    }
  ],
  defines: [
    {
      selector: 'body',
      title: '',
      names: ['d-pgn', 'd-business-unit', 'd-domain-id', 'g-l', 'g-c'],
      values: ['chart', 'dm', 'chart', 'pgn:[pgn]', 'dm-chart-click']
    },
    {
      selector: '.Topclickbox .more',
      title: '',
      names: ['g-a'],
      values: ['click-dm-chart-dealtrend-more'],
      click: true
    },
  ]
}
