module.exports = {
  id: 21,
  matches: [
    {
      title: '品牌详情页',
      host: '/^www.([a-z0-9]+.)?(dealmoon|dazhe).[a-z.]{2,10}/i',
      path: '/^(/(cn|bg|en))?/brands/[^/]+$/i',
      query: '',
      screenName: ''
    }
  ],
  defines: [
    {
      selector: 'body',
      title: '',
      names: ['d-pgn', 'd-business-unit', 'd-domain-id', 'g-l', 'g-c'],
      values: ['branddetail', 'dm', 'brand', 'pgn:[pgn]', 'dm-brand-click']
    },
    {
      selector: '.brand-cooperation',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-collaboration'],
      click: true
    },
    {
      selector: '.content-nav .deals',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-tab-deals'],
      click: true
    },
    {
      selector: '.content-nav .post-guides',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-tab-moments'],
      click: true
    },
    {
      selector: '.content-nav .product-reviews',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-tab-reviews'],
      click: true
    },
    {
      selector: '.content-nav .brand-news',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-tab-news/products'],
      click: true
    },
    {
      selector: '.deal-list .dm-sp-card',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-deals'],
      click: true
    },
    {
      selector: '.post-guide-list a.guide-item',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-moments'],
      click: true
    },
    {
      selector: '.product-review-list .guide-item,.product-review-list .activity',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-reviews'],
      click: true
    },
    {
      selector: '.brand-news-list .dm-sp-card,.brand-news-list .brand-news-item',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-news/products'],
      click: true
    },
    {
      selector: '.brand-item.deals .see-all',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-deals-more'],
      click: true
    },
    {
      selector: '.brand-item.post-guides .see-all',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-moments-more'],
      click: true
    },
    {
      selector: '.brand-item.product-reviews .see-all',
      title: '',
      names: ['g-a'],
      values: ['click-dm-branddetail-reviews-more'],
      click: true
    },
  ]
}
